var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('img',{attrs:{"src":require("@/assets/images/logo-smart-378.svg")}})]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center bg-register",attrs:{"lg":"6"}}),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"6"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Crea il tuo "),_c('b',{staticClass:"text-primary"},[_vm._v("Account")])]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v("Inserisci le informazioni richieste.")]),_c('form-wizard',{ref:"registerForm",staticClass:"steps-transparent mb-3",attrs:{"color":"#018001","title":null,"subtitle":null,"finish-button-text":"Registrati","back-button-text":"Indietro","next-button-text":"Avanti"},on:{"on-complete":_vm.register}},[_c('tab-content',{attrs:{"title":"Account","icon":"feather icon-user","before-change":_vm.validationAccount}},[_c('validation-observer',{ref:"accountTab"},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Tipologia account","label-for":"account_type"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Tipologia account"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"account_type","state":errors.length > 0 ? false : null},model:{value:(_vm.account_type),callback:function ($$v) {_vm.account_type=$$v},expression:"account_type"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Please select an option")]),_c('b-form-select-option',{attrs:{"value":1}},[_vm._v("Installatore")]),_c('b-form-select-option',{attrs:{"value":2}},[_vm._v("Manutentore")]),_c('b-form-select-option',{attrs:{"value":4}},[_vm._v("Installatore & Manutentore")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nome referente","label-for":"user_name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Nome referente"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user_name","placeholder":"Mario","state":errors.length > 0 ? false : null},model:{value:(_vm.user_name),callback:function ($$v) {_vm.user_name=$$v},expression:"user_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Cognome referente","label-for":"user_surname"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Cognome referente"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user_surname","placeholder":"Rossi","state":errors.length > 0 ? false : null},model:{value:(_vm.user_surname),callback:function ($$v) {_vm.user_surname=$$v},expression:"user_surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Ragione sociale","label-for":"user_companyname"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Ragione sociale"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user_companyname","placeholder":"Nome Azienda S.r.l","state":errors.length > 0 ? false : null},model:{value:(_vm.user_companyname),callback:function ($$v) {_vm.user_companyname=$$v},expression:"user_companyname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Partita IVA","label-for":"user_vatnumber"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Partita IVA"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user_vatnumber","placeholder":"IT0123456789","state":errors.length > 0 ? false : null},model:{value:(_vm.user_vatnumber),callback:function ($$v) {_vm.user_vatnumber=$$v},expression:"user_vatnumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"user_email"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user_email","type":"email","placeholder":"mario.rossi@email.com","state":errors.length > 0 ? false : null},model:{value:(_vm.user_email),callback:function ($$v) {_vm.user_email=$$v},expression:"user_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":{required: true},"name":"Accetto Termini/Condizioni"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false : null,"unchecked-valule":"","value":true},on:{"change":_vm.onChangeAcceptance},model:{value:(_vm.accept_terms_conditions),callback:function ($$v) {_vm.accept_terms_conditions=$$v},expression:"accept_terms_conditions"}},[_vm._v(" Accetto "),_c('a',{attrs:{"href":_vm.STORAGE_URL + '/terms_conditions.pdf',"target":"_blank"}},[_vm._v("termini e le condizioni di utilizzo")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Indirizzi","icon":"feather icon-home","before-change":_vm.validationAddress}},[_c('validation-observer',{ref:"addressTab"},[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label":"Indirizzo (sede legale)","label-for":"user_address_headquarter"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user_address_headquarter","placeholder":"Via Roma, 14","state":errors.length > 0 ? false : null},model:{value:(_vm.user_address_headquarter),callback:function ($$v) {_vm.user_address_headquarter=$$v},expression:"user_address_headquarter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"CAP","label-for":"user_zipcode_headquarter"}},[_c('validation-provider',{attrs:{"rules":"required","name":"CAP"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user_zipcode_headquarter","placeholder":"01234","state":errors.length > 0 ? false : null},model:{value:(_vm.user_zipcode_headquarter),callback:function ($$v) {_vm.user_zipcode_headquarter=$$v},expression:"user_zipcode_headquarter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Città","label-for":"user_city_headquarter"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Città"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user_city_headquarter","placeholder":"Milano","state":errors.length > 0 ? false : null},model:{value:(_vm.user_city_headquarter),callback:function ($$v) {_vm.user_city_headquarter=$$v},expression:"user_city_headquarter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"user_province_headquarter","label":"Provincia"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Provincia"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user_province_headquarter","placeholder":"Provincia","state":errors.length > 0 ? false : null},model:{value:(_vm.user_province_headquarter),callback:function ($$v) {_vm.user_province_headquarter=$$v},expression:"user_province_headquarter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label":"Indirizzo (sede operativa)","label-for":"user_address_operationoffice"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Indirizzo (sede operativa)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user_address_operationoffice","placeholder":"Via Roma, 14","state":errors.length > 0 ? false : null},model:{value:(_vm.user_address_operationoffice),callback:function ($$v) {_vm.user_address_operationoffice=$$v},expression:"user_address_operationoffice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"CAP","label-for":"user_zipcode_operationoffice"}},[_c('validation-provider',{attrs:{"rules":"required","name":"CAP (sede operativa)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user_zipcode_operationoffice","placeholder":"01234","state":errors.length > 0 ? false : null},model:{value:(_vm.user_zipcode_operationoffice),callback:function ($$v) {_vm.user_zipcode_operationoffice=$$v},expression:"user_zipcode_operationoffice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Città","label-for":"user_city_operationoffice"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Città (sede operativa)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user_city_operationoffice","placeholder":"Milano","state":errors.length > 0 ? false : null},model:{value:(_vm.user_city_operationoffice),callback:function ($$v) {_vm.user_city_operationoffice=$$v},expression:"user_city_operationoffice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Provincia (sede operativa)","label-for":"user_province_operationoffice"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Città (sede operativa)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user_province_operationoffice","placeholder":"Provincia","state":errors.length > 0 ? false : null},model:{value:(_vm.user_province_operationoffice),callback:function ($$v) {_vm.user_province_operationoffice=$$v},expression:"user_province_operationoffice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Fatturazione","icon":"feather icon-credit-card","before-change":_vm.validationBilling}},[_c('validation-observer',{ref:"billingTab"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"user_sdi","label":"SDI"}},[_c('validation-provider',{attrs:{"rules":"required","name":"SDI"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user_sdi","placeholder":"01234567891012","state":errors.length > 0 ? false : null},model:{value:(_vm.user_sdi),callback:function ($$v) {_vm.user_sdi=$$v},expression:"user_sdi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"user_pec","label":"PEC"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"PEC"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user_pec","placeholder":"nomeazienda@pec.it","state":errors.length > 0 ? false : null},model:{value:(_vm.user_pec),callback:function ($$v) {_vm.user_pec=$$v},expression:"user_pec"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"user_ateco","label":"Codice Ateco"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Ateco"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user_ateco","placeholder":"01234567","state":errors.length > 0 ? false : null},model:{value:(_vm.user_ateco),callback:function ($$v) {_vm.user_ateco=$$v},expression:"user_ateco"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.register_feedback != '')?_c('b-col',{attrs:{"md":"12"}},[_c('b-alert',{staticClass:"py-1 px-1",attrs:{"variant":"danger","show":_vm.register_feedback != ''}},[_vm._v(_vm._s(_vm.register_feedback)+" ")])],1):_vm._e()],1)],1)],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Hai già un account?")]),_c('b-link',{attrs:{"to":{ name: 'login' }}},[_c('span',[_vm._v(" Effettua l'accesso")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }