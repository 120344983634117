<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img src="@/assets/images/logo-smart-378.svg" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center bg-register"></b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Crea il tuo
            <b class="text-primary">Account</b>
          </b-card-title>
          <b-card-text class="mb-2">Inserisci le informazioni richieste.</b-card-text>

          <!-- form -->
          <form-wizard ref="registerForm" color="#018001" :title="null" :subtitle="null" finish-button-text="Registrati"
            back-button-text="Indietro" next-button-text="Avanti" class="steps-transparent mb-3"
            @on-complete="register">
            <!-- Account -->
            <tab-content title="Account" icon="feather icon-user" :before-change="validationAccount">
              <validation-observer ref="accountTab">
                <b-row>
                  <b-col>
                    <b-form-group label="Tipologia account" label-for="account_type">
                      <validation-provider rules="required" #default="{ errors }" name="Tipologia account">
                        <b-form-select  id="account_type" v-model="account_type" 
                          :state="errors.length > 0 ? false : null" >
                          <b-form-select-option :value="null">Please select an option</b-form-select-option>
                          <b-form-select-option :value="1">Installatore</b-form-select-option>
                          <b-form-select-option :value="2">Manutentore</b-form-select-option>
                          <!-- <b-form-select-option :value="3">Utilizzatore</b-form-select-option> -->
                          <b-form-select-option :value="4">Installatore & Manutentore</b-form-select-option>
                          </b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Nome referente" label-for="user_name">
                      <validation-provider rules="required" #default="{ errors }" name="Nome referente">
                        <b-form-input id="user_name" v-model="user_name" placeholder="Mario"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Cognome referente" label-for="user_surname">
                      <validation-provider rules="required" #default="{ errors }" name="Cognome referente">
                        <b-form-input id="user_surname" v-model="user_surname" placeholder="Rossi"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Ragione sociale" label-for="user_companyname">
                      <validation-provider rules="required" #default="{ errors }" name="Ragione sociale">
                        <b-form-input id="user_companyname" v-model="user_companyname" placeholder="Nome Azienda S.r.l"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Partita IVA" label-for="user_vatnumber">
                      <validation-provider rules="required" #default="{ errors }" name="Partita IVA">
                        <b-form-input id="user_vatnumber" v-model="user_vatnumber" placeholder="IT0123456789"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Email" label-for="user_email">
                      <validation-provider rules="required|email" #default="{ errors }" name="Email">
                        <b-form-input id="user_email" v-model="user_email" type="email"
                          placeholder="mario.rossi@email.com" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <validation-provider :rules="{required: true}" #default="{ errors }" name="Accetto Termini/Condizioni">
                      <b-form-checkbox @change="onChangeAcceptance" :state="errors.length > 0 ? false : null" unchecked-valule="" :value="true" v-model="accept_terms_conditions"> Accetto <a :href="STORAGE_URL + '/terms_conditions.pdf'" target="_blank">termini e le condizioni di utilizzo</a></b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- Indirizzi -->
            <tab-content title="Indirizzi" icon="feather icon-home" :before-change="validationAddress">
              <validation-observer ref="addressTab">
                <b-row>
                  <b-col md="8">
                    <b-form-group label="Indirizzo (sede legale)" label-for="user_address_headquarter">
                      <validation-provider rules="required" #default="{ errors }" name="Email">
                        <b-form-input id="user_address_headquarter" v-model="user_address_headquarter"
                          placeholder="Via Roma, 14" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="CAP" label-for="user_zipcode_headquarter">
                      <validation-provider rules="required" #default="{ errors }" name="CAP">
                        <b-form-input id="user_zipcode_headquarter" v-model="user_zipcode_headquarter"
                          placeholder="01234" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Città" label-for="user_city_headquarter">
                      <validation-provider rules="required" #default="{ errors }" name="Città">
                        <b-form-input id="user_city_headquarter" placeholder="Milano" v-model="user_city_headquarter"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="user_province_headquarter" label="Provincia">
                      <validation-provider rules="required" #default="{ errors }" name="Provincia">
                        <b-form-input id="user_province_headquarter" v-model="user_province_headquarter"
                          placeholder="Provincia" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="8">
                    <b-form-group label="Indirizzo (sede operativa)" label-for="user_address_operationoffice">
                      <validation-provider rules="required" #default="{ errors }" name="Indirizzo (sede operativa)">
                        <b-form-input id="user_address_operationoffice" v-model="user_address_operationoffice"
                          placeholder="Via Roma, 14" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="CAP" label-for="user_zipcode_operationoffice">
                      <validation-provider rules="required" #default="{ errors }" name="CAP (sede operativa)">
                        <b-form-input id="user_zipcode_operationoffice" v-model="user_zipcode_operationoffice"
                          placeholder="01234" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Città" label-for="user_city_operationoffice">
                      <validation-provider rules="required" #default="{ errors }" name="Città (sede operativa)">
                        <b-form-input id="user_city_operationoffice" v-model="user_city_operationoffice"
                          placeholder="Milano" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Provincia (sede operativa)" label-for="user_province_operationoffice">
                      <validation-provider rules="required" #default="{ errors }" name="Città (sede operativa)">
                        <b-form-input id="user_province_operationoffice" v-model="user_province_operationoffice"
                          placeholder="Provincia" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- Fatturazione -->
            <tab-content title="Fatturazione" icon="feather icon-credit-card" :before-change="validationBilling">
              <validation-observer ref="billingTab">
                <b-row>
                  <b-col md="12">
                    <b-form-group label-for="user_sdi" label="SDI">
                      <validation-provider rules="required" #default="{ errors }" name="SDI">
                        <b-form-input id="user_sdi" v-model="user_sdi" placeholder="01234567891012"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group label-for="user_pec" label="PEC">
                      <validation-provider rules="required|email" #default="{ errors }" name="PEC">
                        <b-form-input id="user_pec" v-model="user_pec" placeholder="nomeazienda@pec.it"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group label-for="user_ateco" label="Codice Ateco">
                      <validation-provider rules="required" #default="{ errors }" name="Ateco">
                        <b-form-input id="user_ateco" v-model="user_ateco" placeholder="01234567"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12" v-if="register_feedback != ''">
                    <b-alert class="py-1 px-1" variant="danger" :show="register_feedback != ''">{{ register_feedback }}
                    </b-alert>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>
          </form-wizard>

          <p class="text-center mt-2">
            <span>Hai già un account?</span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp;Effettua l'accesso</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BAlert, BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
  BFormSelect,BFormSelectOption
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Requests } from '@/api/requests.js'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { resolve } from 'path'
import { STORAGE_URL } from '@/config';

export default {
  components: {
    VuexyLogo,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BFormSelect,
    BFormSelectOption
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      STORAGE_URL: STORAGE_URL,
      account_type: '',
      user_email: '',
      user_name: '',
      user_surname: '',
      user_companyname: '',
      user_vatnumber: '',
      accept_terms_conditions: '',
      user_address_headquarter: '',
      user_zipcode_headquarter: '',
      user_city_headquarter: '',
      user_province_headquarter: '',
      user_address_operationoffice: '',
      user_zipcode_operationoffice: '',
      user_city_operationoffice: '',
      user_province_operationoffice: '',
      user_sdi: '',
      user_pec: '',
      user_ateco: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      register_feedback: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },

  created() {
    localize('it');
  },

  methods: {
    onChangeAcceptance(checked) {
      debugger; 
      if(checked === false) 
        this.accept_terms_conditions = ''
    },
    
    validationAccount() {
      return new Promise((resolve, reject) => {
        this.$refs.accountTab.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    validationAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressTab.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    validationBilling() {
      return new Promise((resolve, reject) => {
        this.$refs.billingTab.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    async register() {
      this.register_feedback = '';

      var data = new FormData();
      data.append('user_email', this.user_email);
      data.append('account_type', this.account_type);
      data.append('user_name', this.user_name);
      data.append('user_surname', this.user_surname);
      data.append('user_companyname', this.user_companyname);
      data.append('user_vatnumber', this.user_vatnumber);
      data.append('user_address_headquarter', this.user_address_headquarter);
      data.append('user_zipcode_headquarter', this.user_zipcode_headquarter);
      data.append('user_city_headquarter', this.user_city_headquarter);
      data.append('user_province_headquarter', this.user_province_headquarter);
      data.append('user_address_operationoffice', this.user_address_operationoffice);
      data.append('user_zipcode_operationoffice', this.user_zipcode_operationoffice);
      data.append('user_city_operationoffice', this.user_city_operationoffice);
      data.append('user_province_operationoffice', this.user_province_operationoffice);
      data.append('user_sdi', this.user_sdi);
      data.append('user_pec', this.user_pec);
      data.append('user_ateco', this.user_ateco);

      try {
        const response = await Requests.register(data)
        console.log(response);
        if (response.register.success == 'true') {
          this.$router.push({ path: '/verify_email', query: { mail: this.user_email } })
        } else {
          this.register_feedback = response.register.feedback;
        }
      } catch (err) {
        console.log(err);
      }
    }
  },
}
/* eslint-disable global-require */
</script>

<style scoped>
.bg-register {
  background-image: url("../assets/images/bg-login.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-wizard.scss";
</style>
